<template>
  <div>
    <b-card
      no-body
      class="card-payment"
    >
      <b-card-header>
        <b-card-title>Processing Fee</b-card-title>
      </b-card-header>
      <b-card-body>
        <div v-if="!loading">
          <b-table-simple
            v-for="(item, index) in listProcessingFee"
            :key="`list-item-${index}`"
            hover
            class="mb-4"
          >
            <b-tbody>
              <b-tr>
                <b-th width="50%">
                  <h3>
                    {{ item.title }}
                  </h3>
                </b-th>
                <b-td />
              </b-tr>
              <template v-for="(itemChild, indexChild) in item.child">
                <b-tr :key="`list-child-${indexChild}-${index}`">
                  <b-th width="50%">
                    Type / Country
                  </b-th>
                  <b-td width="50%">
                    {{ itemChild.key.split('_').reduce((acc, word) => acc + ' ' + word.charAt(0).toUpperCase() + word.slice(1), '').trim() }}
                  </b-td>
                </b-tr>
                <template v-for="(itemField, indexField) in field">
                  <b-tr :key="`list-field-${indexField}-${indexChild}`">
                    <b-th width="50%">
                      {{ itemField.title }}
                    </b-th>
                    <b-td width="50%">
                      {{ data[item.key][itemChild.key][itemField.tag] || '-' }}
                      {{ data[itemChild.key] }}
                    </b-td>
                  </b-tr>
                </template>
                <b-tr :key="`divicer-${indexChild}`">
                  <b-th
                    width="50%"
                    class="py-1"
                  />
                  <b-td />
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </div>
        <div v-else>
          <b-skeleton width="85%" />
          <b-skeleton width="55%" />
          <b-skeleton width="70%" />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BSkeleton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BSkeleton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      listProcessingFee: [
        {
          key: 'dragonpay',
          title: 'Dragonpay',
          child: [
            { key: 'philippines_gcash',}
          ]
        },
        {
          key: 'google_play',
          title: 'Google Play',
          child: [
            { key: 'international',}
          ]
        },
        {
          key: 'paypal',
          title: 'Paypal',
          child: [
            { key: 'international',},
            { key: 'malaysia',},
            { key: 'philippines',},
            { key: 'singapore',},
          ]
        },
        {
          key: 'senangpay',
          title: 'Senangpay',
          child: [
            { key: 'malaysia_default',},
          ]
        },
        {
          key: 'siampay',
          title: 'Siampay',
          child: [
            { key: 'thailand_alipay',},
            { key: 'thailand_line_pay',},
            { key: 'thailand_qr_promptpay',},
            { key: 'thailand_shopeepay',},
            { key: 'thailand_truemoney',},
            { key: 'thailand_wechat',},
          ]
        },
        {
          key: 'stripe',
          title: 'Stripe',
          child: [
            { key: 'international_card',},
            { key: 'malaysia_card',},
            { key: 'malaysia_grabpay',},
            { key: 'singapore_card_sgd',},
            { key: 'singapore_card_usd',},
            { key: 'singapore_grabpay',},
            { key: 'singapore_paynow',},
            { key: 'thailand_card',},
            { key: 'thailand_promptpay',},
          ]
        },
        {
          key: 'xendit',
          title: 'Xendit',
          child: [
            { key: 'indonesia_astrapay',},
            { key: 'indonesia_card',},
            { key: 'indonesia_dana',},
            { key: 'indonesia_internet_banking',},
            { key: 'indonesia_ovo',},
            { key: 'indonesia_qris',},
            { key: 'indonesia_shopeepay',},
          ]
        },
        {
          key: 'zalopay',
          title: 'Zalopay',
          child: [
            { key: 'vietnam_ewallet',},
            { key: 'vietnam_internet_banking',},
            { key: 'vietnam_qr',},
          ]
        },
      ],
      field: [
        {
          tag: 'percentage',
          title: 'Percentage',
        },
        {
          tag: 'plus_flat_currency',
          title: 'Currency',
        },
        {
          tag: 'plus_flat_price',
          title: 'Price',
        },
      ]
    }
  },
  computed: {
    loading() {
      return this.$store.state.processingFee.loadingDetail
    },
    data() {
      return this.$store.state.processingFee.data
    },
  },
  mounted() {
    this.$store.dispatch('processingFee/getData')
  },
}
</script>
